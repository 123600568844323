html,
body {
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
}
body {
  touch-action: none;
}

:root {
  font-family: Tahoma, Arial, Helvetica, sans-serif;
}

/*----Leaflet Styling----*/
#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: -1;
  overflow: hidden;
  touch-action: pan-x pan-y;
  overscroll-behavior-y: none;
}

.hidden {
  display: none !important;
}

/*----Affiliate Advertising----*/
.aff-hidden {
  opacity: 0;
  transition: opacity 1s ease;
}

.aff-visible {
  opacity: 1 !important;
  transition: opacity 1s ease;
}

.affiliate-disclaimer {
  font-style: italic;
  font-size: 5px;
  color: rgba(45, 45, 45, 0.2);
  padding: 1px;
  text-decoration: none;
}

.affiliate-popup {
  overflow: hidden;
}

.affiliate-disclaimer,
.affiliate-target-1,
.affiliate-target-2,
.affiliate-target-3,
.affiliate-target-4,
.affiliate-target-5,
.affiliate-target-6,
.affiliate-target-name-1,
.affiliate-target-name-2,
.affiliate-target-name-3,
.affiliate-target-name-4,
.affiliate-target-name-5,
.affiliate-target-name-6 {
  position: absolute;
  text-align: center;
}

.affiliate-disclaimer {
  top: -5px;
  right: 70px;
}

.affiliate-target-1 {
  top: 7px;
  right: 58px;
}

.affiliate-target-2 {
  top: 10px;
  right: 58px;
}

.affiliate-target-3 {
  top: 3px;
  right: 57px;
}

.affiliate-target-4 {
  top: 20px;
  right: 55px;
}

.affiliate-target-5 {
  top: 10px;
  right: 57px;
}

.affiliate-target-6 {
  top: 10px;
  right: 56px;
}

.affiliate-target-name-1,
.affiliate-target-name-2,
.affiliate-target-name-3,
.affiliate-target-name-4,
.affiliate-target-name-5,
.affiliate-target-name-6 {
  font-size: 12px;
  color: rgba(45, 45, 45, 0.86);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  padding: 4px 6px 4px 8px;
}

.affiliate-target-name-1 {
  top: 97px;
  right: 58px;
}

.affiliate-target-name-2 {
  top: 100px;
  right: 58px;
}

.affiliate-target-name-3 {
  top: 114px;
  right: 72.5px;
}

.affiliate-target-name-4 {
  top: 80px;
  right: 69px;
}

.affiliate-target-name-5 {
  top: 113px;
  right: 73px;
}

.affiliate-target-name-6 {
  top: 102px;
  right: 53px;
}

/* .affiliate-popup-text {
  font-size: 12px;
  border-radius: 5px;
  text-decoration: none;
  padding: 4px 6px 4px 8px;
  text-align: center;
} */

@media screen and (max-width: 350px) {
  .affiliate-disclaimer,
  .affiliate-target-1,
  .affiliate-target-2,
  .affiliate-target-3,
  .affiliate-target-4,
  .affiliate-target-5,
  .affiliate-target-6,
  .affiliate-target-name-1,
  .affiliate-target-name-2,
  .affiliate-target-name-3,
  .affiliate-target-name-4,
  .affiliate-target-name-5,
  .affiliate-target-name-6 {
    display: none;
  }
}

/* For SF Police Stations and Responding Cop Car Emoji */
.station-marker {
  background: transparent;
  border: none;
  font-size: x-large;
  touch-action: none;
  pointer-events: none;
}

.response-marker {
  background: transparent;
  border: none;
  font-size: large;
}

.response-marker-popup-text {
  color: rgb(0, 0, 255);
}

#addSFDataSource {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 62px;
  padding: 0 0 0 4px;
  box-sizing: border-box;
  display: block;
  align-items: center;
  text-decoration: none;
  color: #0057b7;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: 2;
  font-size: 12px;
  cursor: pointer;
  border-radius: 0 4px 0 0;
  line-height: 1.4;
  user-select: none;
  text-align: center;
}

#addSFDataSource a:hover {
  text-decoration: underline;
}

#addSFDataSource a {
  text-decoration: none;
}
#addSFDataSource a.centered-link {
  display: flex;
  align-items: center;
}

#addSFDataSource a.centered-link img {
  margin-right: 3px;
}

#addSFDataSource a,
#addSFDataSource a:visited,
.leaflet-control-attribution a,
.leaflet-control-attribution a:visited {
  color: #0057b7;
}

#alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 15%;
  font-size: 28px;
  font-family: Arial, sans-serif;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  border-radius: 10px;
  user-select: none;
  touch-action: none;
  overflow: hidden;
  word-wrap: normal;
}

/* -----Buttons----- */
#project-info-btn {
  top: 9px;
  border-radius: 5px 5px 0px 0px;
  border: 0.2px solid #989898;
  border-bottom: none;
  background-clip: padding-box;
}

#change-map-btn {
  top: 43px;
  border-radius: 0px 0px 5px 5px;
  border: 0.2px solid #989898;
  border-top: none;
}

#response-times-list-btn {
  top: 82px;
  border-radius: 5px 5px 0px 0px;
  border: 0.2px solid #989898;
  border-bottom: none;
}

#car-breakins-btn {
  top: 116px;
  border-radius: 0px 0px 5px 5px;
  border: 0.2px solid #989898;
  border-top: none;
}

#text-message-btn {
  border-radius: 5px 5px 0px 0px;
  bottom: 248px;
  border: 0.2px solid #989898;
  border-bottom: none;
}

#text-message-content {
  display: none;
}

#tweet-btn {
  border-radius: 0px 0px 5px 5px;
  bottom: 214px;
  border: 0.2px solid #989898;
  border-top: none;
}

#tweet-content {
  display: none;
}

#nearby-list-btn {
  bottom: 160px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

#latest-list-btn {
  bottom: 120px;
  color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
  font-size: 20px !important;
  border-radius: 5px;
  background-image: linear-gradient(#79766e, #5b5953);
  text-shadow: none !important;
  width: 80px !important;
}

@media screen and (min-width: 598px) {
  #nearby-list-btn {
    bottom: 80px;
  }
  #latest-list-btn {
    bottom: 40px;
  }

  #text-message-btn {
    bottom: 173px;
  }

  #tweet-btn {
    bottom: 139px;
  }

  #button-container-3 {
    bottom: 139px !important;
  }

  #weather {
    top: 8px;
    font-size: 27px !important;
    padding: 0px 6px 1px 6px;
  }

  #nearby-info {
    top: 50px !important;
    font-size: 22px !important;
    padding: 4px 8px 4px 8px;
  }

  #neighborhood-text {
    padding: 5px 30px 6px 30px !important;
    bottom: 93px !important;
    font-size: 46px !important;
  }

  #last-updated {
    bottom: 32px !important;
    padding: 7px 30px 9px 30px !important;
    font-size: 18px !important;
  }
}

#nearby-info,
#weather {
  background-color: rgba(245, 243, 241, 1);
}

#text-message-btn,
#tweet-btn,
#change-map-btn,
#latest-list-btn,
#nearby-list-btn,
#nearby-info,
#neighborhood-text,
#project-info-btn,
#response-times-list-btn,
#car-breakins-btn,
#weather {
  position: fixed;
  z-index: 3;
  color: #5b5953;
  text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  user-select: none;
  touch-action: none;
  vertical-align: center;
  transition: transform 0.5s ease;
}

#weather,
#neighborhood-text,
#nearby-info,
#last-updated {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

#button-container-1,
#button-container-2,
#button-container-3 {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
  right: 15px;
  position: fixed;
  z-index: 1;
  width: 34px;
  height: 68px;
  border-radius: 5px;
  background-color: transparent;
}

#button-container-1 {
  top: 9px;
}

#button-container-2 {
  top: 82px;
}

#button-container-3 {
  bottom: 214px;
}

#text-message-btn,
#tweet-btn,
#change-map-btn,
#nearby-list-btn,
#project-info-btn,
#response-times-list-btn,
#car-breakins-btn {
  background-image: linear-gradient(#f4f3f1, #eeede9);
}

#text-message-btn:hover,
#tweet-btn:hover,
#latest-list-btn:hover,
#change-map-btn:hover,
#nearby-list-btn:hover,
#project-info-btn:hover,
#response-times-list-btn:hover,
#car-breakins-btn:hover {
  color: #1c1c1a;
}

.bi-chat-dots,
.bi-twitter-x,
.bi-crosshair,
.bi-map,
.bi-crosshair,
.bi-stopwatch,
.bi-car-front,
.bi-info-square {
  width: 30px;
  height: 30px;
}

#text-message-btn,
#tweet-btn,
#change-map-btn,
#latest-list-btn,
#nearby-list-btn,
#response-times-list-btn,
#car-breakins-btn {
  cursor: pointer;
}

#text-message-btn,
#tweet-btn,
#change-map-btn,
#latest-list-btn,
#nearby-list-btn,
#project-info-btn,
#response-times-list-btn,
#car-breakins-btn {
  font-weight: bold;
  text-align: center;
  width: 34px;
  height: 34px;
  z-index: 5;
  right: 15px;
  background-clip: padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

#latest-list-btn,
#nearby-list-btn {
  border: none;
}

#text-message-btn,
#response-times-list-btn,
#project-info-btn {
  border-bottom: 0.2px solid #989898;
}

#text-message-btn:active,
#tweet-btn:active,
#change-map-btn:active,
#latest-list-btn:active,
#nearby-list-btn:active,
#project-info-btn:active,
#car-breakins-btn:active {
  transform: scale(1.025);
  transform-origin: left;
  transition: transform 0.2s ease-in-out;
  box-shadow: none;
  outline: none;
}

#count-container {
  align-items: center;
  justify-content: center;
  height: 20px;
  touch-action: none;
  pointer-events: none;
}

#nearby-info {
  left: 50px;
  top: 48px;
  font-size: 14px;
  padding: 4px 8px 4px 8px;
  touch-action: none;
  pointer-events: none;
}

.bi-info-square {
  cursor: help !important;
}

#neighborhood-text {
  bottom: 72px;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 3;
  background-color: rgba(245, 243, 241, 0.8);
  padding: 1px 10px 4px 10px;
  font-size: 40px;
  text-align: center;
  touch-action: none;
  pointer-events: none;
}

#containerTemp {
  grid-column: 1;
  grid-row: 1;
  touch-action: none;
  pointer-events: none;
}

#weather {
  top: 8px;
  left: 50px;
  font-size: 23px;
  padding: 0px 6px 1px 6px;
  text-align: center;
}

.leaflet-control-attribution {
  width: 65px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: 4px 0 0 0;
  user-select: none;
  box-sizing: border-box;
}

.leaflet-control-attribution a,
.leaflet-control-attribution a:visited,
#addSFDataSource a,
#addSFDataSource a:visited {
  color: #3288bd;
}

.leaflet-popup-content-wrapper,
.station-popup .leaflet-popup-content,
.leaflet-popup-tip {
  color: #5b5953;
}

.leaflet-popup-content {
  padding: 0px 0px 0px 0px;
  background-color: transparent;
  transition: padding 0.5s;
}

.leaflet-popup-content a {
  color: #888888;
}

.leaflet-popup-content-small-padding {
  padding: 0.1px 0px 0px 0px;
  background-color: transparent;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  padding: 0.1px;
  background-color: rgba(255, 255, 255, 0.9);
}

.leaflet-control-zoom-in {
  top: 9px;
  cursor: zoom-in !important;
}

.leaflet-control-zoom-out {
  top: 54px;
  cursor: zoom-out !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  background-image: linear-gradient(#f7f7f7, #e7e7e7);
  transition: transform 0.2s ease-in-out;
  user-select: none;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
}

a.leaflet-control-zoom-in,
a.leaflet-control-zoom-out {
  color: #5b5953;
}

a.leaflet-control-zoom-in:hover,
a.leaflet-control-zoom-out:hover {
  color: #1c1c1a;
}

.leaflet-control-zoom-in:active,
.leaflet-control-zoom-out:active {
  background-color: #f8f8f8;
  outline: none;
  transform: scale(1.04);
  transform-origin: top;
}

#last-updated {
  font-size: 12px;
  text-align: center;
  color: white;
  line-height: 1.4;
  position: fixed;
  bottom: 28px;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 2;
  color: #736f65;
  background-color: rgba(245, 243, 241, 0.8);
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.7);
  padding: 4px 10px 6px 10px;
  touch-action: none;
  pointer-events: none;
}

@media screen and (max-width: 370px) {
  #last-updated {
    bottom: 15px;
  }
}

.station-popup .leaflet-popup-content {
  text-align: center;
}

/* ---- Dark Mode ---- */
@media (prefers-color-scheme: dark) {
  .leaflet-popup-content-wrapper,
  .station-popup .leaflet-popup-content,
  .leaflet-popup-tip {
    color: rgba(255, 255, 255, 0.94);
    background-color: rgba(26, 26, 26, 1);
  }

  #nearby-info,
  #weather {
    background-color: rgba(28, 28, 28, 1);
    border: none;
    color: rgba(250, 249, 248, 0.95);
  }

  #neighborhood-text,
  #last-updated {
    background-color: rgba(28, 28, 28, 0.8);
    color: rgba(250, 249, 248, 0.95);
    border: none;
    white-space: nowrap;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  #tweet-btn,
  #text-message-btn,
  #change-map-btn,
  #nearby-list-btn,
  #project-info-btn,
  #response-times-list-btn,
  #car-breakins-btn {
    background-image: linear-gradient(#2f2f2f, #1d1d1d);
    color: rgba(250, 250, 250, 0.95) !important;
  }

  #latest-list-btn {
    background-image: linear-gradient(#707070, #454545);
    color: rgba(250, 250, 250, 0.95) !important;
  }

  #tweet-btn:hover,
  #text-message-btn:hover,
  #latest-list-btn:hover,
  #change-map-btn:hover,
  #nearby-list-btn:hover,
  #project-info-btn:hover,
  #response-times-list-btn:hover,
  #car-breakins-btn:hover {
    color: rgba(135, 135, 135, 0.95) !important;
  }

  .leaflet-popup-content-wrapper,
  .station-popup .leaflet-popup-content,
  .leaflet-popup-tip,
  #weather,
  #nearby-info,
  #neighborhood-text,
  #last-updated,
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  #tweet-btn,
  #text-message-btn,
  #change-map-btn,
  #latest-list-btn,
  #nearby-list-btn,
  #project-info-btn,
  #response-times-list-btn,
  #car-breakins-btn {
    text-shadow: none;
    box-shadow: 2px 2px 3px 2px rgba(30, 30, 30, 0.4);
  }

  .leaflet-control-zoom-in:active,
  .leaflet-control-zoom-out:active {
    background-color: #333333;
    border: none;
  }

  a.leaflet-control-zoom-in,
  a.leaflet-control-zoom-out {
    color: rgba(250, 250, 250, 0.95) !important;
  }

  a.leaflet-control-zoom-in:hover,
  a.leaflet-control-zoom-out:hover {
    color: rgba(135, 135, 135, 0.95) !important;
  }

  .leaflet-control-attribution,
  #addSFDataSource {
    background-color: rgba(30, 30, 30, 0.9) !important;
    border: none;
  }

  .response-marker-popup-text {
    color: rgb(27, 27, 255);
  }

  .affiliate-disclaimer {
    color: rgba(255, 255, 255, 0.2);
  }
  .affiliate-target-name-1,
  .affiliate-target-name-2,
  .affiliate-target-name-3,
  .affiliate-target-name-4,
  .affiliate-target-name-5,
  .affiliate-target-name-6 {
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(30, 30, 30, 0.9);
  }

  #project-info-btn {
    border-radius: 0px 0px 0px 0px !important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
  }

  #change-map-btn {
    border-radius: 0px 0px 0px 0px !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }

  #response-times-list-btn {
    border-radius: 0px 0px 0px 0px !important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
  }

  #car-breakins-btn {
    border-radius: 0px 0px 0px 0px !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }

  #text-message-btn {
    border-radius: 0px 0px 0px 0px !important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
  }

  #tweet-btn {
    border-radius: 0px 0px 0px 0px !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }

  #nearby-list-btn {
    border-radius: 0px 0px 0px 0px !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }
}
