html, body {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

body {
  touch-action: none;
}

:root {
  font-family: Tahoma, Arial, Helvetica, sans-serif;
}

#map {
  width: 100vw;
  height: 100%;
  z-index: -1;
  touch-action: pan-x pan-y;
  overscroll-behavior-y: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.aff-hidden {
  opacity: 0;
  transition: opacity 1s;
}

.aff-visible {
  transition: opacity 1s;
  opacity: 1 !important;
}

.affiliate-disclaimer {
  color: #2d2d2d33;
  padding: 1px;
  font-size: 5px;
  font-style: italic;
  text-decoration: none;
}

.affiliate-popup {
  overflow: hidden;
}

.affiliate-disclaimer, .affiliate-target-1, .affiliate-target-2, .affiliate-target-3, .affiliate-target-4, .affiliate-target-5, .affiliate-target-6, .affiliate-target-name-1, .affiliate-target-name-2, .affiliate-target-name-3, .affiliate-target-name-4, .affiliate-target-name-5, .affiliate-target-name-6 {
  text-align: center;
  position: absolute;
}

.affiliate-disclaimer {
  top: -5px;
  right: 70px;
}

.affiliate-target-1 {
  top: 7px;
  right: 58px;
}

.affiliate-target-2 {
  top: 10px;
  right: 58px;
}

.affiliate-target-3 {
  top: 3px;
  right: 57px;
}

.affiliate-target-4 {
  top: 20px;
  right: 55px;
}

.affiliate-target-5 {
  top: 10px;
  right: 57px;
}

.affiliate-target-6 {
  top: 10px;
  right: 56px;
}

.affiliate-target-name-1, .affiliate-target-name-2, .affiliate-target-name-3, .affiliate-target-name-4, .affiliate-target-name-5, .affiliate-target-name-6 {
  color: #2d2d2ddb;
  background-color: #fffc;
  border-radius: 5px;
  padding: 4px 6px 4px 8px;
  font-size: 12px;
  text-decoration: none;
}

.affiliate-target-name-1 {
  top: 97px;
  right: 58px;
}

.affiliate-target-name-2 {
  top: 100px;
  right: 58px;
}

.affiliate-target-name-3 {
  top: 114px;
  right: 72.5px;
}

.affiliate-target-name-4 {
  top: 80px;
  right: 69px;
}

.affiliate-target-name-5 {
  top: 113px;
  right: 73px;
}

.affiliate-target-name-6 {
  top: 102px;
  right: 53px;
}

@media screen and (width <= 350px) {
  .affiliate-disclaimer, .affiliate-target-1, .affiliate-target-2, .affiliate-target-3, .affiliate-target-4, .affiliate-target-5, .affiliate-target-6, .affiliate-target-name-1, .affiliate-target-name-2, .affiliate-target-name-3, .affiliate-target-name-4, .affiliate-target-name-5, .affiliate-target-name-6 {
    display: none;
  }
}

.station-marker {
  touch-action: none;
  pointer-events: none;
  background: none;
  border: none;
  font-size: x-large;
}

.response-marker {
  background: none;
  border: none;
  font-size: large;
}

.response-marker-popup-text {
  color: #00f;
}

#addSFDataSource {
  width: 62px;
  box-sizing: border-box;
  color: #0057b7;
  z-index: 2;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  background-color: #ffffffa6;
  border-radius: 0 4px 0 0;
  align-items: center;
  padding: 0 0 0 4px;
  font-size: 12px;
  line-height: 1.4;
  text-decoration: none;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
}

#addSFDataSource a:hover {
  text-decoration: underline;
}

#addSFDataSource a {
  text-decoration: none;
}

#addSFDataSource a.centered-link {
  align-items: center;
  display: flex;
}

#addSFDataSource a.centered-link img {
  margin-right: 3px;
}

#addSFDataSource a, #addSFDataSource a:visited, .leaflet-control-attribution a, .leaflet-control-attribution a:visited {
  color: #0057b7;
}

#alert {
  width: 70%;
  height: 15%;
  text-align: center;
  color: #fff;
  z-index: 8;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  word-wrap: normal;
  background-color: #0009;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  font-family: Arial, sans-serif;
  font-size: 28px;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

#project-info-btn {
  background-clip: padding-box;
  border: .2px solid #989898;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  top: 9px;
}

#change-map-btn {
  border: .2px solid #989898;
  border-top: none;
  border-radius: 0 0 5px 5px;
  top: 43px;
}

#response-times-list-btn {
  border: .2px solid #989898;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  top: 82px;
}

#car-breakins-btn {
  border: .2px solid #989898;
  border-top: none;
  border-radius: 0 0 5px 5px;
  top: 116px;
}

#text-message-btn {
  border: .2px solid #989898;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  bottom: 248px;
}

#text-message-content {
  display: none;
}

#tweet-btn {
  border: .2px solid #989898;
  border-top: none;
  border-radius: 0 0 5px 5px;
  bottom: 214px;
}

#tweet-content {
  display: none;
}

#nearby-list-btn {
  border-radius: 5px;
  bottom: 160px;
  box-shadow: 0 0 6px 1px #00000080;
}

#latest-list-btn {
  background-image: linear-gradient(#79766e, #5b5953);
  border-radius: 5px;
  bottom: 120px;
  box-shadow: 0 0 6px 1px #00000080;
  color: #fff !important;
  text-shadow: none !important;
  width: 80px !important;
  font-size: 20px !important;
}

@media screen and (width >= 598px) {
  #nearby-list-btn {
    bottom: 80px;
  }

  #latest-list-btn {
    bottom: 40px;
  }

  #text-message-btn {
    bottom: 173px;
  }

  #tweet-btn {
    bottom: 139px;
  }

  #button-container-3 {
    bottom: 139px !important;
  }

  #weather {
    padding: 0 6px 1px;
    top: 8px;
    font-size: 27px !important;
  }

  #nearby-info {
    padding: 4px 8px;
    font-size: 22px !important;
    top: 50px !important;
  }

  #neighborhood-text {
    padding: 5px 30px 6px !important;
    font-size: 46px !important;
    bottom: 93px !important;
  }

  #last-updated {
    padding: 7px 30px 9px !important;
    font-size: 18px !important;
    bottom: 32px !important;
  }
}

#nearby-info, #weather {
  background-color: #f5f3f1;
}

#text-message-btn, #tweet-btn, #change-map-btn, #latest-list-btn, #nearby-list-btn, #nearby-info, #neighborhood-text, #project-info-btn, #response-times-list-btn, #car-breakins-btn, #weather {
  z-index: 3;
  color: #5b5953;
  text-shadow: 0 1px 2px #fffc;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  vertical-align: center;
  transition: transform .5s;
  position: fixed;
}

#weather, #neighborhood-text, #nearby-info, #last-updated {
  border-radius: 4px;
  box-shadow: 0 0 6px 1px #00000080;
}

#button-container-1, #button-container-2, #button-container-3 {
  z-index: 1;
  width: 34px;
  height: 68px;
  background-color: #0000;
  border-radius: 5px;
  position: fixed;
  right: 15px;
  box-shadow: 0 0 6px 1px #00000080;
}

#button-container-1 {
  top: 9px;
}

#button-container-2 {
  top: 82px;
}

#button-container-3 {
  bottom: 214px;
}

#text-message-btn, #tweet-btn, #change-map-btn, #nearby-list-btn, #project-info-btn, #response-times-list-btn, #car-breakins-btn {
  background-image: linear-gradient(#f4f3f1, #eeede9);
}

#text-message-btn:hover, #tweet-btn:hover, #latest-list-btn:hover, #change-map-btn:hover, #nearby-list-btn:hover, #project-info-btn:hover, #response-times-list-btn:hover, #car-breakins-btn:hover {
  color: #1c1c1a;
}

.bi-chat-dots, .bi-twitter-x, .bi-crosshair, .bi-map, .bi-crosshair, .bi-stopwatch, .bi-car-front, .bi-info-square {
  width: 30px;
  height: 30px;
}

#text-message-btn, #tweet-btn, #change-map-btn, #latest-list-btn, #nearby-list-btn, #response-times-list-btn, #car-breakins-btn {
  cursor: pointer;
}

#text-message-btn, #tweet-btn, #change-map-btn, #latest-list-btn, #nearby-list-btn, #project-info-btn, #response-times-list-btn, #car-breakins-btn {
  text-align: center;
  width: 34px;
  height: 34px;
  z-index: 5;
  background-clip: padding-box;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  right: 15px;
}

#latest-list-btn, #nearby-list-btn {
  border: none;
}

#text-message-btn, #response-times-list-btn, #project-info-btn {
  border-bottom: .2px solid #989898;
}

#text-message-btn:active, #tweet-btn:active, #change-map-btn:active, #latest-list-btn:active, #nearby-list-btn:active, #project-info-btn:active, #car-breakins-btn:active {
  transform-origin: 0;
  box-shadow: none;
  outline: none;
  transition: transform .2s ease-in-out;
  transform: scale(1.025);
}

#count-container {
  height: 20px;
  touch-action: none;
  pointer-events: none;
  justify-content: center;
  align-items: center;
}

#nearby-info {
  touch-action: none;
  pointer-events: none;
  padding: 4px 8px;
  font-size: 14px;
  top: 48px;
  left: 50px;
}

.bi-info-square {
  cursor: help !important;
}

#neighborhood-text {
  z-index: 3;
  text-align: center;
  touch-action: none;
  pointer-events: none;
  background-color: #f5f3f1cc;
  padding: 1px 10px 4px;
  font-size: 40px;
  bottom: 72px;
  left: 50%;
  transform: translate(-50%, 50%);
}

#containerTemp {
  touch-action: none;
  pointer-events: none;
  grid-area: 1 / 1;
}

#weather {
  text-align: center;
  padding: 0 6px 1px;
  font-size: 23px;
  top: 8px;
  left: 50px;
}

.leaflet-control-attribution {
  width: 65px;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  background-color: #ffffffa6;
  border-radius: 4px 0 0;
  font-size: 12px;
}

.leaflet-control-attribution a, .leaflet-control-attribution a:visited, #addSFDataSource a, #addSFDataSource a:visited {
  color: #3288bd;
}

.leaflet-popup-content-wrapper, .station-popup .leaflet-popup-content, .leaflet-popup-tip {
  color: #5b5953;
}

.leaflet-popup-content {
  background-color: #0000;
  padding: 0;
  transition: padding .5s;
}

.leaflet-popup-content a {
  color: #888;
}

.leaflet-popup-content-small-padding {
  background-color: #0000;
  padding: .1px 0 0;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background-color: #ffffffe6;
  padding: .1px;
}

.leaflet-control-zoom-in {
  top: 9px;
  cursor: zoom-in !important;
}

.leaflet-control-zoom-out {
  top: 54px;
  cursor: zoom-out !important;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(#f7f7f7, #e7e7e7);
  transition: transform .2s ease-in-out;
  box-shadow: 0 0 6px 1px #00000080;
}

a.leaflet-control-zoom-in, a.leaflet-control-zoom-out {
  color: #5b5953;
}

a.leaflet-control-zoom-in:hover, a.leaflet-control-zoom-out:hover {
  color: #1c1c1a;
}

.leaflet-control-zoom-in:active, .leaflet-control-zoom-out:active {
  transform-origin: top;
  background-color: #f8f8f8;
  outline: none;
  transform: scale(1.04);
}

#last-updated {
  text-align: center;
  color: #fff;
  z-index: 2;
  color: #736f65;
  text-shadow: 0 1px #ffffffb3;
  touch-action: none;
  pointer-events: none;
  background-color: #f5f3f1cc;
  padding: 4px 10px 6px;
  font-size: 12px;
  line-height: 1.4;
  position: fixed;
  bottom: 28px;
  left: 50%;
  transform: translate(-50%, 50%);
}

@media screen and (width <= 370px) {
  #last-updated {
    bottom: 15px;
  }
}

.station-popup .leaflet-popup-content {
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .leaflet-popup-content-wrapper, .station-popup .leaflet-popup-content, .leaflet-popup-tip {
    color: #fffffff0;
    background-color: #1a1a1a;
  }

  #nearby-info, #weather {
    color: #faf9f8f2;
    background-color: #1c1c1c;
    border: none;
  }

  #neighborhood-text, #last-updated {
    color: #faf9f8f2;
    white-space: nowrap;
    background-color: #1c1c1ccc;
    border: none;
  }

  .leaflet-control-zoom-in, .leaflet-control-zoom-out, #tweet-btn, #text-message-btn, #change-map-btn, #nearby-list-btn, #project-info-btn, #response-times-list-btn, #car-breakins-btn {
    background-image: linear-gradient(#2f2f2f, #1d1d1d);
    color: #fafafaf2 !important;
  }

  #latest-list-btn {
    background-image: linear-gradient(#707070, #454545);
    color: #fafafaf2 !important;
  }

  #tweet-btn:hover, #text-message-btn:hover, #latest-list-btn:hover, #change-map-btn:hover, #nearby-list-btn:hover, #project-info-btn:hover, #response-times-list-btn:hover, #car-breakins-btn:hover {
    color: #878787f2 !important;
  }

  .leaflet-popup-content-wrapper, .station-popup .leaflet-popup-content, .leaflet-popup-tip, #weather, #nearby-info, #neighborhood-text, #last-updated, .leaflet-control-zoom-in, .leaflet-control-zoom-out, #tweet-btn, #text-message-btn, #change-map-btn, #latest-list-btn, #nearby-list-btn, #project-info-btn, #response-times-list-btn, #car-breakins-btn {
    text-shadow: none;
    box-shadow: 2px 2px 3px 2px #1e1e1e66;
  }

  .leaflet-control-zoom-in:active, .leaflet-control-zoom-out:active {
    background-color: #333;
    border: none;
  }

  a.leaflet-control-zoom-in, a.leaflet-control-zoom-out {
    color: #fafafaf2 !important;
  }

  a.leaflet-control-zoom-in:hover, a.leaflet-control-zoom-out:hover {
    color: #878787f2 !important;
  }

  .leaflet-control-attribution, #addSFDataSource {
    border: none;
    background-color: #1e1e1ee6 !important;
  }

  .response-marker-popup-text {
    color: #1b1bff;
  }

  .affiliate-disclaimer {
    color: #fff3;
  }

  .affiliate-target-name-1, .affiliate-target-name-2, .affiliate-target-name-3, .affiliate-target-name-4, .affiliate-target-name-5, .affiliate-target-name-6 {
    color: #ffffffe6;
    background-color: #1e1e1ee6;
  }

  #project-info-btn {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
  }

  #change-map-btn {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
  }

  #response-times-list-btn {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
  }

  #car-breakins-btn {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
  }

  #text-message-btn {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
  }

  #tweet-btn, #nearby-list-btn {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
  }
}

/*# sourceMappingURL=index.ea127994.css.map */
